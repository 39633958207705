import type { CookieConsentConfig } from "vanilla-cookieconsent";
import { acceptedCategory } from "vanilla-cookieconsent";
import { sendGTMEvent } from "../Analytics";

// Update the Window Object to include the Clarity function
declare global {
    interface Window {
        clarity: (action: string, value?: boolean) => void;
    }
}

const pluginConfig: CookieConsentConfig = {
    revision: 3,
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false,
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false,
        },
    },

    onFirstConsent: ({ cookie }) => {
        console.log("onFirstConsent", cookie);
        if (acceptedCategory("analytics")) {
            window.clarity("consent");
            console.log(
                "onFirstConsent",
                sendGTMEvent([
                    "consent",
                    "update",
                    {
                        analytics_storage: "granted",
                    },
                ]),
            );
        }

        if (acceptedCategory("advertising")) {
            window.clarity("consent", false);
            sendGTMEvent([
                "consent",
                "update",
                {
                    ad_storage: "granted",
                    ad_personalization: "granted",
                },
            ]);
        }
    },

    onConsent: ({ cookie }) => {
        console.log("onConsent", cookie);
    },

    onChange: ({ changedCategories, cookie }) => {
        console.log("onChange", changedCategories, cookie);
        if (changedCategories.includes("analytics")) {
            if (acceptedCategory("analytics")) {
                window.clarity("consent");
                console.log(
                    "onChange",
                    sendGTMEvent([
                        "consent",
                        "update",
                        {
                            analytics_storage: "granted",
                        },
                    ]),
                );
            } else {
                window.clarity("consent", false);
                console.log(
                    "onChange",
                    sendGTMEvent([
                        "consent",
                        "update",
                        {
                            analytics_storage: "denied",
                        },
                    ]),
                );
            }
        }

        if (changedCategories.includes("advertising")) {
            if (acceptedCategory("advertising")) {
                sendGTMEvent([
                    "consent",
                    "update",
                    {
                        ad_storage: "granted",
                        ad_personalization: "granted",
                    },
                ]);
            } else {
                sendGTMEvent([
                    "consent",
                    "update",
                    {
                        ad_storage: "denied",
                        ad_personalization: "denied",
                    },
                ]);
            }
        }
    },

    categories: {
        necessary: {
            readOnly: true,
        },
        advertising: {},
        analytics: {},
    },

    language: {
        default: "de",
        autoDetect: "browser",
        translations: {
            de: {
                consentModal: {
                    title: "Hallo!",
                    description:
                        "Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Ausserdem geben wir Informationen zu deiner Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die du ihnen bereitgestellt hast oder die sie im Rahmen deiner Nutzung der Dienste gesammelt haben. \nWeitere Informationen:<a href='/de/datenschutz'>Datenschutzerklärung</a> /<a href='/de/impressum'>Impressum</a> \n",
                    closeIconLabel: "",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    showPreferencesBtn: "Einstellungen verwalten",
                    footer: '<a href="/de/datenschutz">Datenschutz</a>\n<a href="/de/agb">AGB</a>',
                },
                preferencesModal: {
                    title: "Präferenzen für die Zustimmung",
                    closeIconLabel: "Modal schließen",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    savePreferencesBtn: "Einstellungen speichern",
                    serviceCounterLabel: "Dienstleistungen",
                    sections: [
                        {
                            title: "Cookie Erklärung",
                            description:
                                "Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung effizienter zu gestalten. Laut Gesetz können wir Cookies auf deinem Gerät speichern, wenn diese für den Betrieb dieser Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir deine Erlaubnis. Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien platziert, die auf unseren Seiten erscheinen.\nDu kannst deine Einwilligung jederzeit von der Cookie-Erklärung auf unserer Website ändern oder widerrufen. ",
                            linkedCategory: "necessary",
                        },
                        {
                            title: 'Notwendige Cookies <span class="pm__badge">Immer Aktiviert</span>',
                            description:
                                "Pflichtfeld - kann nicht abgewählt werden. Notwendige Cookies helfen dabei, die Videos auf der  Webseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Das Abspielen der Videos kann ohne diese Cookies nicht richtig funktionieren. ",
                            linkedCategory: "necessary",
                        },
                        {
                            title: "Analytische Cookies",
                            description:
                                "Analytische Cookies helfen uns zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und ausgewertet werden.",
                            linkedCategory: "analytics",
                        },
                        {
                            title: "Marketing Cookies",
                            description:
                                'Marketing-Cookies erfassen Informationen über das Online-Verhalten von Besuchern, um die Auslieferung von Werbung zu optimieren. Sie zielen darauf ab, Werbeinhalte anzuzeigen, die für die Präferenzen des Nutzers potenziell interessanter sind, und unterstützen so eine effizientere Werbekommunikation zwischen Webseitenbetreibern und Nutzern.\n\nBei Fragen zu den Cookies und den Wahlmöglichkeiten wende dich bitte an <a class="cc__link" href="@rico-brunner.com">uns</a>.',
                            linkedCategory: "advertising",
                        },
                    ],
                },
            },
            en: {
                consentModal: {
                    title: "Hello!",
                    description:
                        "We use cookies to personalise content and advertisements, to offer social media functions and to analyse access to our website. We also share information about your use of our website with our social media, advertising and analytics partners. Our partners may combine this information with other data that you have provided to them or that they have collected as part of your use of the services. \nFurther information: Privacy policy / Imprint ",
                    closeIconLabel: "",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Manage preferences",
                    footer: '<a href="#link">Privacy Policy</a>\n<a href="#link">Terms and conditions</a>',
                },
                preferencesModal: {
                    title: "Consent Preferences Center",
                    closeIconLabel: "Close modal",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Cookie declaration",
                            description:
                                "Cookies are small text files used by websites to make the user experience more efficient. By law, we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your permission. This site uses different types of cookies. Some cookies are placed by third parties that appear on our pages.\nYou can change or withdraw your consent at any time from the cookie statement on our website. ",
                            linkedCategory: "necessary",
                        },
                        {
                            title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                            description:
                                "Mandatory field - cannot be deselected. Necessary cookies help to make the videos on the website usable by enabling basic functions such as page navigation and access to secure areas of the website. Playing the videos cannot function properly without these cookies. ",
                            linkedCategory: "necessary",
                        },
                        {
                            title: "Analytische Cookies",
                            description:
                                "Analytical cookies help us to understand how visitors interact with our website by collecting and reporting information anonymously.",
                            linkedCategory: "analytics",
                        },
                        {
                            title: "Marketing Cookies",
                            description:
                                'Marketing cookies collect information about visitors\' online behaviour in order to optimise the delivery of advertising. They aim to display advertising content that is potentially relevant to the user\'s preferences and support a more efficient advertising communication between website operators and users.\n\nFor questions about the cookies and the options, please contact <a class="cc__link" href="@rico-brunner.com">us</a>.',
                            linkedCategory: "advertising",
                        },
                    ],
                },
            },
        },
    },
};

export default pluginConfig;
