"use client";
import { useEffect } from "react";
import * as CookieConsent from "vanilla-cookieconsent";
import pluginConfig from "./CookieConsentConfig";

const CookieConsentComponent = () => {
    useEffect(() => {
        CookieConsent.run(pluginConfig);
    }, []);

    return (
        <button
            onClick={CookieConsent.showPreferences}
            type="button"
            className="hidden"
        />
    );
};

export default CookieConsentComponent;
